import React from "react"
import styled from "styled-components"
import MoonLoader from "react-spinners/MoonLoader"

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: ${({ display }) => display};
  justify-content: center;
  align-items: center;
`

const Loading = ({ loading }) => {
  return (
    <Root display={loading ? "flex" : "none"}>
      <MoonLoader size={100} color="#A2A2A2" loading={loading} />
    </Root>
  )
}

export default Loading
