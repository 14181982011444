import React, { useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby-link"

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`

const NoMatch = () => {
  useEffect(() => {
    setTimeout(() => {
      navigate("/admin/")
    }, 1500)
  }, [])

  return (
    <Root>
      <div style={{ textAlign: "center" }}>
        <h1>Page doesn't exist</h1>
        <h2>No redirecting you...</h2>
      </div>
    </Root>
  )
}

export default NoMatch
